import React from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Grid,
  Popconfirm,
  Row,
  Space,
} from "antd";
import { useAppSelector } from "hooks/index";
import { getPermission } from "features/permissions/PermissionSlice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RecordContext from "contexts/RecordContext";
import { FixedAssetAddition } from "types/FixedAssetAddition";

export const SubmitButton = ({ buttonText }: { buttonText?: string }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const permissions = useAppSelector(getPermission("fixedAssetAddition"));
  const [isOpen, setIsOpen] = React.useState(false);

  const handleValidation = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((_err) => {
        setIsOpen(true);
      });
  };

  const { componentDisabled } = ConfigProvider.useConfig();
  const screens = Grid.useBreakpoint();
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const isEditable =
    permissions?.canWrite &&
    (record.status === "draft" || record.status === "awaiting");

  if (componentDisabled) {
    return (
      <Row>
        <Col
          sm={16}
          xs={24}
          offset={screens.xs ? 0 : 8}
          style={{ marginTop: 20 }}
        >
          <Space>
            {isEditable && (
              <Link to="edit">
                <Button type="primary" disabled={false}>
                  {t("forms.buttons.edit")}
                </Button>
              </Link>
            )}
            <Link to="/tenant/fixed_asset_additions">
              <Button htmlType="button" disabled={false}>
                {t("forms.buttons.cancel")}
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <Form.Item
      wrapperCol={{ offset: screens.xs ? 0 : 8, span: screens.xs ? 24 : 16 }}
      style={{ marginTop: 20 }}
    >
      <Popconfirm
        title="You have not filled complete details"
        open={isOpen}
        onConfirm={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        disabled
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              handleValidation();
              form.setFieldValue(["fixed_asset_addition", "status"], "draft");
            }}
          >
            {t("fixed_asset_additions.buttons.save_as_draft")}
          </Button>
          {permissions.canApprove ? (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleValidation();
                form.setFieldValue(
                  ["fixed_asset_addition", "status"],
                  "completed",
                );
              }}
            >
              {buttonText || t("forms.buttons.approve")}
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() =>
                form.setFieldValue(
                  ["fixed_asset_addition", "status"],
                  "awaiting",
                )
              }
            >
              {t("forms.buttons.save")}
            </Button>
          )}
          <Link to="/tenant/fixed_asset_additions">
            <Button htmlType="button">{t("forms.buttons.cancel")}</Button>
          </Link>
        </Space>
      </Popconfirm>
    </Form.Item>
  );
};
