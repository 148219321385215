import axios from "axios";
import { Inventories } from "types/Inventory";
import { QueryParameters } from "types/QueryParameters";

export const getInventories = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<Inventories> => {
  const { data } = await axios.get("/tenant/inventories.json", {
    params: {
      "q[name_or_ar_name_cont]": search,
      "q[s]": sorter || "name asc",
      page,
      per_page: pageSize || 20,
      ...filters,
    },
  });
  return data.inventories;
};
