import { Form } from "antd";
import React from "react";

type WatchProps = {
  name: string | (string | number)[];
  onChange: (value?: any) => void;
  children: React.ReactNode | React.ReactNode[];
};

const Watch = ({ name, onChange, children }: WatchProps) => {
  const form = Form.useFormInstance();
  const formVals = Form.useWatch(name, form);

  React.useEffect(() => {
    onChange(formVals);
  }, [form, formVals]);

  return children;
};

export default Watch;
