import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Skeleton, message } from "antd";

import QPageLayout from "components/layout/QPageLayout";
import { useMutation, useQuery } from "react-query";
import {
  createFixedAssetAddition,
  initFixedAssetAddition,
} from "api/FixedAssetAdditions";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";
import BackButton from "components/layout/BackButton";
import { FixedAssetAdditionCreateBillForm } from "components/tenant/FixedAssetAdditions/CreateBill";
import RecordContext from "contexts/RecordContext";

export const FixedAssetAdditionNewCreateBill = () => {
  const { t } = useTranslation();
  const [messageAPI, msgContext] = message.useMessage();
  const navigate = useNavigate();

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: (
        <Link to="/tenant/fixed_asset_additions">
          {t("fixed_asset_additions.layout.title")}
        </Link>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.new"),
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: "new_fixed_asset_addition",
    queryFn: () => initFixedAssetAddition(),
  });

  const fixedAssetAdditionMutation = useMutation({
    mutationFn: createFixedAssetAddition,
    onSuccess: (_data) => {
      navigate(`/tenant/fixed_asset_additions`);
    },
    onError: (error: AxiosError<ApiError>) => {
      messageAPI.open({
        type: "error",
        content: error.response?.data.errors || t("forms.messages.error"),
      });
    },
  });

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
      messageHolder={msgContext}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <RecordContext.Provider value={data}>
          <FixedAssetAdditionCreateBillForm
            mutation={fixedAssetAdditionMutation}
          />
        </RecordContext.Provider>
      )}
    </QPageLayout>
  );
};
