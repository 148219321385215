import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { CalculateDeprecation } from "../CalculateDepreciation";
import { Form, Spin } from "antd";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "features/query-keys";
import { getAssetRegistries } from "api/AssetRegistry";

export const DepreciationDetails = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billItemsWatch = Form.useWatch(["bill", "bill_items_attributes"], form);
  const registryWatch = Form.useWatch(["asset_registry"], form);

  const registries = React.useRef([mainRegistry]);

  const fixedAssetIDs = [];
  billItemsWatch &&
    billItemsWatch.forEach((billItem: any) => {
      fixedAssetIDs.push(billItem.product_id);
    });
  const filters = { filters: { "q[fixed_asset_id_in]": fixedAssetIDs } };
  const assetRegistryQuery = useQuery({
    queryKey: assetRegistryKeys.list(filters),
    queryFn: () => {
      if (fixedAssetIDs.length < 1) return;

      return getAssetRegistries(filters);
    },
  });

  if (assetRegistryQuery.data) {
    const allRegistries = [mainRegistry];

    assetRegistryQuery.data.forEach((assetRegistry) => {
      if (mainRegistry.id === assetRegistry.id) return;

      allRegistries.push(assetRegistry);
    });

    registries.current = allRegistries;
  } else {
    registries.current = [mainRegistry];
  }

  React.useEffect(() => {
    if (!billItemsWatch) return;

    assetRegistryQuery.refetch();
  }, [form, billItemsWatch, registryWatch]);

  const depreciationComponent = (
    <CalculateDeprecation registries={registries.current} />
  );
  if (assetRegistryQuery.isLoading)
    return <Spin size="small">{depreciationComponent}</Spin>;

  return depreciationComponent;
};
