import React from "react";
import { Link } from "react-router-dom";
import { Flex, Typography, theme } from "antd";
import { CopyOutlined, CodeSandboxOutlined } from "@ant-design/icons";

import QPageLayout from "components/layout/QPageLayout";
import { useTranslation } from "react-i18next";

import { FixedAssetAdditionCard } from "./Card";
import { FixedAssetAdditionNewMerge } from "./Merge";
import { FixedAssetAdditionNewLinkBill } from "./LinkBill";
import { FixedAssetAdditionNewCreateBill } from "./CreateBill";
import BackButton from "components/layout/BackButton";

const FixedAssetAdditionNew = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { useToken } = theme;
  const { token } = useToken();
  const headerStyle = {
    color: token.colorPrimary,
    fontSize: "24px",
  };

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: (
        <Link to="/tenant/fixed_asset_additions">
          {t("fixed_asset_additions.layout.title")}
        </Link>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.new"),
    },
  ];

  const additionTypes = [
    {
      name: "merge",
      icon: <CopyOutlined style={{ fontSize: "40px" }} />,
      text: t("fixed_asset_additions.layout.merge_assets"),
      link: "/tenant/fixed_asset_additions/new/merge",
    },
    {
      name: "link_bill",
      icon: <CodeSandboxOutlined style={{ fontSize: "40px" }} />,
      text: t("fixed_asset_additions.layout.link_bill"),
      link: "/tenant/fixed_asset_additions/new/link_bill",
    },
    {
      name: "create_bill",
      icon: <CodeSandboxOutlined style={{ fontSize: "40px" }} />,
      text: t("fixed_asset_additions.layout.create_bill"),
      link: "/tenant/fixed_asset_additions/new/create_bill",
    },
  ];

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
    >
      <Flex
        gap={"64px"}
        style={{ minHeight: "60vh" }}
        justify="center"
        align="center"
        vertical
      >
        <Text style={headerStyle}>Select Addition Type</Text>
        <Flex justify="center" align="center" gap={"42px"} wrap={"wrap"}>
          {additionTypes.map((el, i) => {
            return (
              <Link to={el.link} key={`card-${i}`}>
                <Flex style={{ cursor: "pointer" }}>
                  <FixedAssetAdditionCard text={el.text} icon={el.icon} />
                </Flex>
              </Link>
            );
          })}
        </Flex>
      </Flex>
    </QPageLayout>
  );
};

FixedAssetAdditionNew.Merge = FixedAssetAdditionNewMerge;
FixedAssetAdditionNew.LinkBill = FixedAssetAdditionNewLinkBill;
FixedAssetAdditionNew.CreateBill = FixedAssetAdditionNewCreateBill;

export { FixedAssetAdditionNew };
