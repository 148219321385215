import { Button, Card, DatePicker, Modal } from "antd";
import { updateAssetRegistry } from "api/AssetRegistry";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { AssetRegistry } from "types/AssetRegistry";

const FillReceiveDate = ({
  assetRegistry,
  onChange,
}: {
  assetRegistry: AssetRegistry;
  onChange?: (registry: AssetRegistry) => void;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const startDepreciationDate = React.useRef<string>();

  const registryMuttation = useMutation({
    mutationFn: (data: { start_depreciation_date: string }) => {
      return updateAssetRegistry({
        id: assetRegistry.id,
        ...data,
      });
    },
    onSuccess: (response: AssetRegistry) => {
      setIsModalOpen(false);
      onChange && onChange(response);
    },
  });

  const updateRegistry = () => {
    registryMuttation.mutate({
      start_depreciation_date: startDepreciationDate.current,
    });
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)} size="small">
        Fill
      </Button>
      <Modal
        open={isModalOpen}
        onOk={updateRegistry}
        onCancel={() => setIsModalOpen(false)}
        title={t("activerecord.attributes.fixed_asset.received_date")}
        width="50%"
      >
        <Card>
          <DatePicker
            style={{ width: "60%" }}
            onChange={(_dateFormat, date: string) =>
              (startDepreciationDate.current = date)
            }
          />
        </Card>
      </Modal>
    </>
  );
};

export { FillReceiveDate };
