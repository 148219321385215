import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Spin, message } from "antd";

import QPageLayout from "components/layout/QPageLayout";
import { UseMutationResult, useMutation, useQuery } from "react-query";
import {
  getFixedAssetAddition,
  updateFixedAssetAddition,
} from "api/FixedAssetAdditions";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";
import BackButton from "components/layout/BackButton";
import { FixedAssetAdditionMergeForm } from "components/tenant/FixedAssetAdditions/Merge";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import { FixedAssetAdditionLinkBillForm } from "../LinkBill";
import NoMatch from "components/layout/NoMatch";
import { FixedAssetAdditionCreateBillForm } from "../CreateBill";
import { fixedAssetAdditionKeys } from "features/query-keys";
import DisabledContext, {
  DisabledContextProvider,
} from "antd/lib/config-provider/DisabledContext";
import RecordContext from "contexts/RecordContext";

const EditForm = ({
  record,
  mutation,
}: {
  record: FixedAssetAddition;
  mutation: UseMutationResult<FixedAssetAddition>;
}) => {
  if (record.addition_type === "asset_merge") {
    return <FixedAssetAdditionMergeForm mutation={mutation} />;
  } else if (record.addition_type === "link_bill") {
    return <FixedAssetAdditionLinkBillForm mutation={mutation} />;
  } else if (record.addition_type === "create_bill") {
    return <FixedAssetAdditionCreateBillForm mutation={mutation} />;
  }

  return <NoMatch />;
};

export const FixedAssetAdditionEdit = (props: { disabled?: boolean }) => {
  const { t } = useTranslation();
  const [messageAPI, msgContext] = message.useMessage();
  const navigate = useNavigate();
  const { additionID } = useParams();

  const { data, isLoading, isError } = useQuery({
    queryKey: fixedAssetAdditionKeys.detail(additionID),
    queryFn: () => getFixedAssetAddition(additionID),
  });

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: (
        <Link to="/tenant/fixed_asset_additions">
          {t("fixed_asset_additions.layout.title")}
        </Link>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.new"),
    },
  ];

  const fixedAssetAdditionMutation = useMutation({
    mutationFn: updateFixedAssetAddition(Number(additionID)),
    onSuccess: (_data) => {
      navigate(`/tenant/fixed_asset_additions`);
    },
    onError: (error: AxiosError<ApiError>) => {
      let errorMsg = t("forms.messages.error");
      if (error.response?.data?.message?.errors) {
        errorMsg = error.response?.data?.message?.errors;
      }
      messageAPI.open({
        type: "error",
        content: errorMsg,
      });
    },
  });

  const contextDisabled = React.useContext(DisabledContext);
  const { disabled = contextDisabled } = props;

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
      messageHolder={msgContext}
    >
      <DisabledContextProvider disabled={disabled}>
        <div className={disabled ? "show-page" : ""}>
          {isLoading || isError ? (
            <Spin size="large">
              <FixedAssetAdditionMergeForm
                mutation={fixedAssetAdditionMutation}
              />
            </Spin>
          ) : (
            <RecordContext.Provider value={data}>
              <EditForm mutation={fixedAssetAdditionMutation} record={data} />
            </RecordContext.Provider>
          )}
        </div>
      </DisabledContextProvider>
    </QPageLayout>
  );
};
