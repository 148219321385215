import axios from "axios";
import {
  FixedAssetAddition,
  FixedAssetAdditionWithErrors,
} from "types/FixedAssetAddition";

export async function getFixedAssetAdditions(
  page: number,
  filters,
  pageSize: number,
  sorter: string,
) {
  const { data } = await axios.get(`/tenant/fixed_asset_additions.json`, {
    params: {
      page,
      per_page: pageSize,
      "q[s]": sorter,
      ...filters,
    },
  });
  return data;
}

export async function getFixedAssetAddition(
  fixedAssetAdditionID: number | string,
) {
  const { data } = await axios.get(
    `/tenant/fixed_asset_additions/${fixedAssetAdditionID}.json`,
  );
  return data;
}

export async function deleteFixedAssetAddition(record: FixedAssetAddition) {
  try {
    const { data } = await axios.delete(
      `/tenant/fixed_asset_additions/${record.id}.json`,
    );

    return data;
  } catch (error) {
    throw error?.response.data || error;
  }
}

export const createFixedAssetAddition = async (
  values: HTMLFormElement,
): Promise<FixedAssetAddition> => {
  const { data } = await axios.post(
    "/tenant/fixed_asset_additions.json",
    values,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return data;
};

export const updateFixedAssetAddition =
  (fixedAssetAdditionID: number) =>
  async (values: HTMLFormElement): Promise<FixedAssetAddition> => {
    const { data } = await axios.put(
      `/tenant/fixed_asset_additions/${fixedAssetAdditionID}.json`,
      values,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    return data;
  };

export const initFixedAssetAddition = async (): Promise<FixedAssetAddition> => {
  const { data } = await axios.get("/tenant/fixed_asset_additions/new.json");
  return data;
};

export const validateFixedAssetAddition = async (values: {
  fixed_asset_addition: any;
}): Promise<FixedAssetAdditionWithErrors> => {
  const { data } = await axios.post(
    "/tenant/fixed_asset_additions/validate.json",
    values,
  );
  return data;
};
