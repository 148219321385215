import { Table } from "antd";
import EditableTable from "components/EditableTable";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { AssetRegistry } from "types/AssetRegistry";

export const CalculationDepreciationEntry = ({
  registeries,
  isEditable = false,
}: {
  registeries: AssetRegistry[];
  isEditable?: boolean;
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      key: "fixed_asset_name",
      dataIndex: "fixed_asset_name",
      title: t("activerecord.attributes.fixed_asset.asset_name"),
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: t("activerecord.attributes.fixed_asset.registered_asset_ref"),
    },
    {
      key: "description",
      dataIndex: "description",
      title: t("activerecord.attributes.depreciation.desc"),
      editable: true,
      formName: (record: AssetRegistry) => [
        "depreciation",
        `${record.id}`,
        "description",
      ],
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: t("activerecord.attributes.depreciation.amount"),
      editable: true,
      formName: (record: AssetRegistry) => [
        "depreciation",
        `${record.id}`,
        "amount",
      ],
    },
  ];

  const dataSource = registeries.map((registery, index) => ({
    key: index,
    id: registery.id,
    fixed_asset_name:
      i18next.language === "en"
        ? registery.fixed_asset?.en_name
        : registery.fixed_asset?.name,
    sku: registery.sku,
    description: registery.description,
    amount: registery.unbooked_amount,
  }));

  if (isEditable)
    return (
      <EditableTable
        dataSource={dataSource}
        columns={columns}
        keepEditable
        pagination={false}
      />
    );

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};
