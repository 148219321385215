import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { Form, Skeleton } from "antd";
import { getBill } from "api/Bill";

import { useQuery } from "react-query";
import { billKeys } from "features/query-keys";
import { AssetRegistryShow } from "../AssetRegistryShow";

export const AssetRegistryList = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billWatch = Form.useWatch(
    ["fixed_asset_addition", "fixed_asset_addition_transactions_attributes", 0],
    form,
  );

  const registries = React.useRef([]);

  const billQuery = useQuery({
    queryKey: billKeys.detail(billWatch),
    queryFn: () => {
      if (
        !billWatch?.transactionable_id ||
        billWatch?.transactionable_type !== "Bill"
      )
        return;

      return getBill({ billID: billWatch.transactionable_id });
    },
  });

  if (billQuery.data) {
    const allRegistries = [];

    billQuery.data.line_items?.forEach((billItem) => {
      billItem.product?.asset_registries?.forEach((assetRegistry) => {
        if (mainRegistry.id === assetRegistry.id) return;

        const registryWithProduct = {
          ...assetRegistry,
          fixed_asset: billItem.product,
        };
        allRegistries.push(registryWithProduct);
      });
    });

    registries.current = allRegistries;
  } else {
    registries.current = [];
  }

  React.useEffect(() => {
    if (!(billWatch && billWatch.transactionable_id)) return;

    billQuery.refetch();
  }, [form, billWatch]);

  if (billQuery.isLoading) return <Skeleton />;

  return (
    <div style={{ maxHeight: 400, overflowY: "scroll" }}>
      {registries.current.map((registry) => (
        <AssetRegistryShow
          assetRegistry={registry}
          title={registry.sku}
          key={`${registry.id}-${registry.sku}`}
        />
      ))}
    </div>
  );
};
