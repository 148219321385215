import React, { useState, useEffect } from "react";
import { Table, Button, Space, Flex, theme, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDateRanges, getSingleBudget, postBudgetDetails } from "api/budgets";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import BudgetDetailsHeaderSection from "./BudgetDetailsHeaderSection";
import { useBudgetDetailsColumns, useResetRowDataModal } from "hooks/budgets";
import {
  addInitialData,
  getBudgetIdFromUrl,
  updateParentNodes,
  resetDateToZero,
  updateExpandedRowKeys,
} from "helpers/budgets";
const { useToken } = theme;

const BudgetDetails = () => {
  const queryClient = useQueryClient();
  const { token } = useToken();
  if (i18next.language === "ar") {
    moment.locale("ar");
  }
  const { t } = useTranslation();
  const [cycle, setCycle] = useState({ no: 12, type: "monthly" });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [formData, setFormData] = useState({});
  const id = getBudgetIdFromUrl();
  const { data, isLoading } = useQuery({
    queryKey: ["budget_details", id],
    queryFn: () => getSingleBudget(id),
    onSuccess: (data) => {
      setCycle({ no: data?.cycle === "quarterly" ? 4 : 12, type: data?.cycle });
    },
  });

  const { data: dateRanges } = useQuery({
    queryKey: ["date_ranges", id, cycle],
    queryFn: () => getDateRanges(id, cycle.type),
  });
  const [initialRowData, setInitialRowData] = useState({});

  const handleReset = (recordId = null) => {
    setFormData((prevFormData) => {
      let newFormData = { ...prevFormData };
      if (data?.cycle != cycle.type) {
        return resetDateToZero(formData, cycle , recordId);
      } else {
        if (recordId) {
          const initialRecord = initialRowData[recordId];
          if (initialRecord) {
            newFormData[recordId] = { ...initialRecord };

            const updateParent = (formData, parentId) => {
              if (!parentId) return formData;

              let parentRecord = formData[parentId];
              if (parentRecord) {
                parentRecord.budget_amount = parentRecord.children.reduce(
                  (sum, childId) => {
                    const childRecord = formData[childId];
                    return sum + (childRecord ? childRecord.budget_amount : 0);
                  },
                  0
                );

                for (let i = 1; i <= cycle.no; i++) {
                  parentRecord[i] = parentRecord.children.reduce(
                    (sum, childId) => {
                      const childRecord = formData[childId];
                      return sum + (childRecord ? childRecord[i] || 0 : 0);
                    },
                    0
                  );
                }

                formData[parentId] = parentRecord;

                if (parentRecord.parentId)
                  return updateParent(formData, parentRecord.parentId);
              }
              return formData;
            };

            return (
              updateParent(newFormData, initialRecord.parentId) || newFormData
            );
          }
        } else {
          const initialData = {};
          if (data && dateRanges) {
            const calculateBudgetAmount = (values) => {
              return values.reduce((sum, val) => sum + val, 0);
            };

            const addInitialData = (data, parentId = null) => {
              data.forEach((account) => {
                const values = account.budgeting_values?.values || [];
                initialData[account.id] = {
                  ...account,
                  ...Object.fromEntries(
                    Array.from({ length: cycle.no }, (_, i) => [
                      i + 1,
                      values[i] || 0,
                    ])
                  ),
                  budget_amount: calculateBudgetAmount(values),
                  children: account.children.map((child) => child.id),
                  parentId,
                };

                if (account.children.length) {
                  addInitialData(account.children, account.id);
                }
              });
            };
            setIsSaveButtonDisabled(true);
            addInitialData(data?.budgetings);
            newFormData = initialData;
            setInitialRowData(initialData);
          }
        }
      }

      return newFormData;
    });
  };
  const { showResetRowModal, resetRowModal } =
    useResetRowDataModal(handleReset);

  const updateBudgetDetails = useMutation({
    mutationFn: (data) => postBudgetDetails(data, id),
    onSuccess(data, variables, context) {
      message.success(t("budgets.show.budget_details.saved"));
      queryClient.invalidateQueries({ queryKey: ["budget_details"] });
      setIsSaveButtonDisabled(true);
    },
  });

  useEffect(() => {
    if (data && dateRanges && cycle.type == data?.cycle) {
      const initialData = {};
      const keys = [];

      addInitialData(data?.budgetings, cycle, initialData);
      updateParentNodes(initialData, cycle);
      setFormData(initialData);

      const addKeys = (items) => {
        items.forEach((item) => {
          keys.push(item.id);
          if (item.children) {
            addKeys(item.children);
          }
        });
      };
      setFormData(initialData);
      setInitialRowData(initialData);
      addKeys(data.budgetings);
      setExpandedRowKeys(keys);
    }
  }, [data, dateRanges]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(
      updateExpandedRowKeys(expanded, record, expandedRowKeys)
    );
  };

  const handleInputChange = (e, recordId, field) => {
    if (e.target.value >= 0) {
      const value = parseFloat(e.target.value) || 0;
      const updatedRecord = {
        ...formData[recordId],
        [field]: value,
      };

      const numberOfFields = dateRanges.length === 4 ? 4 : 12;
      const ranges = Array.from(
        { length: numberOfFields },
        (_, index) => index + 1
      );

      updatedRecord.budget_amount = ranges?.reduce((sum, value) => {
        return sum + (updatedRecord[value] || 0);
      }, 0);

      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          [recordId]: updatedRecord,
        };

        const updateParent = (formData, parentId) => {
          if (!parentId) return formData;

          let parentRecord = formData[parentId];
          if (parentRecord) {
            parentRecord.budget_amount = parentRecord.children.reduce(
              (sum, childId) => {
                const childRecord = formData[childId];
                return sum + (childRecord ? childRecord.budget_amount : 0);
              },
              0
            );

            for (let i = 1; i <= cycle.no; i++) {
              parentRecord[i] = parentRecord.children.reduce((sum, childId) => {
                const childRecord = formData[childId];
                return sum + (childRecord ? childRecord[i] || 0 : 0);
              }, 0);
            }

            formData[parentId] = parentRecord;

            if (parentRecord.parentId) {
              return updateParent(formData, parentRecord.parentId);
            }
          }

          return formData;
        };

        const finalFormData = updateParent(
          newFormData,
          formData[recordId].parentId
        );
        setIsSaveButtonDisabled(false);
        return finalFormData || newFormData;
      });
    }
    e.preventDefault();
  };

  const handleSubmit = () => {
    const convertObjectToArray = (obj) => {
      return Object.entries(obj).map(([key, value]) => {
        const valuesArray = Array.from(
          { length: cycle.no },
          (_, index) => value[index + 1] || 0
        );
        return {
          budgeting_id: key,
          values: valuesArray,
        };
      });
    };

    const formDataResult = new FormData();

    const result = convertObjectToArray(formData);

    result.forEach((el, i) => {
      formDataResult.append(`budget[budgeting_values][]`, JSON.stringify(el));
    });

    formDataResult.append("budget[cycle]", cycle.type);

    updateBudgetDetails.mutate(formDataResult as any);
  };

  const columns = useBudgetDetailsColumns(
    dateRanges,
    cycle,
    formData,
    handleInputChange,
    showResetRowModal
  );

  const renderTable = (data) => {
    if (!data) return [];
    return data.map((item) => ({
      key: item.id,
      id: item.id,
      code: item.code,
      name: i18next.language === "en" ? item.en_name : item.ar_name,
      budget_amount: formData[item.id]?.budget_amount || 0,
      ...Object.fromEntries(
        Array.from({ length: cycle.no }, (_, index) => [
          index + 1,
          formData[item.id]?.[index + 1] || 0,
        ])
      ),
      children: item.children ? renderTable(item.children) : undefined,
    }));
  };

  const dataSource = renderTable(data?.budgetings);

  const rowExpandable = (record) => {
    return record.children && record.children.length > 0;
  };

  const customExpandIcon = ({ expanded, onExpand, record }) => {
    if (record.children && record.children.length > 0) {
      return (
        <i
          style={{ border: `1px solid ${token.colorPrimary}` }}
          aria-label="icon: caret-right"
          className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
            expanded ? "expanded" : "collapsed"
          }`}
          onClick={(e) => onExpand(record, e)}
        />
      );
    }
    return null;
  };

  return (
    <>
      {!isLoading && (
        <BudgetDetailsHeaderSection
          cycle={cycle}
          cycleCallBack={(value) => {
            setFormData(resetDateToZero(formData, value));
            setCycle(value);
            setIsSaveButtonDisabled(true);
          }}
          data={data}
          onReset={handleReset}
        />
      )}
      <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          rowExpandable,
          expandIcon: customExpandIcon,
        }}
      />
      <Flex justify="end" style={{ marginTop: token.marginLG }}>
        <Space>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={isSaveButtonDisabled}
          >
            {t("budgets.show.save")}
          </Button>
          <Button
            onClick={() => {
              window.history.back();
            }}
          >
            {t("budgets.show.cancel")}
          </Button>
        </Space>
        {resetRowModal}
      </Flex>
    </>
  );
};

export default BudgetDetails;
