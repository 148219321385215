import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { Form, Skeleton } from "antd";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "features/query-keys";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { getAssetRegistries } from "api/AssetRegistry";

export const AssetRegistryList = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billItemsWatch = Form.useWatch(["bill", "bill_items_attributes"], form);

  const registries = React.useRef([]);

  const fixedAssetIDs = [];
  billItemsWatch &&
    billItemsWatch.forEach((billItem: any) => {
      fixedAssetIDs.push(billItem.product_id);
    });
  const filters = { filters: { "q[fixed_asset_id_in]": fixedAssetIDs } };
  const assetRegistryQuery = useQuery({
    queryKey: assetRegistryKeys.list(filters),
    queryFn: () => {
      if (fixedAssetIDs.length < 1) return;

      return getAssetRegistries(filters);
    },
  });

  if (assetRegistryQuery.data) {
    const allRegistries = [];

    assetRegistryQuery.data.forEach((assetRegistry) => {
      if (mainRegistry.id === assetRegistry.id) return;

      allRegistries.push(assetRegistry);
    });

    registries.current = allRegistries;
  } else {
    registries.current = [];
  }

  React.useEffect(() => {
    if (!billItemsWatch) return;

    assetRegistryQuery.refetch();
  }, [form, billItemsWatch]);

  if (assetRegistryQuery.isLoading) return <Skeleton />;

  return (
    <div style={{ maxHeight: 400, overflowY: "scroll" }}>
      {registries.current.map((registry) => (
        <AssetRegistryShow
          assetRegistry={registry}
          title={registry.sku}
          key={`${registry.id}-${registry.sku}`}
        />
      ))}
    </div>
  );
};
