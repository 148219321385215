import axios from "axios";

import { Contacts } from "types/Contact";

export const getVendors = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: {
  search?: string;
  page?: number;
  pageSize?: number;
  sorter?: string;
  filters?: any;
}): Promise<Contacts> => {
  const { data } = await axios.get("/tenant/contacts/vendor_index.json", {
    params: {
      "q[name_cont]": search,
      "q[s]": sorter || "name asc",
      page,
      per_page: pageSize || 20,
      ...filters,
    },
  });
  return data.contacts;
};
