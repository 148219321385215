import { Card, Col, Divider, Form, Image, Input, Row, Typography } from "antd";
import React from "react";

import { fallbackImage } from "assets/fallbackImage";
import { useTranslation } from "react-i18next";
import { AssetRegistry } from "types/AssetRegistry";
import { formatCurrency } from "helpers/format-currency";
import { FillReceiveDate } from "./FillReceiveDate";

export const AssetRegistryShow = ({
  assetRegistry,
  title,
}: {
  assetRegistry: AssetRegistry;
  title: string;
}) => {
  const { t } = useTranslation();
  const [updatedRegistry, setUpdatedRegistry] = React.useState(assetRegistry);
  const form = Form.useFormInstance();

  return (
    <Row style={{ marginLeft: 20, marginBottom: 20 }}>
      <Typography.Title level={4} style={{ color: "#677292", fontWeight: 500 }}>
        {title}
      </Typography.Title>
      <Divider />
      <Col span={24}>
        <Card className="show-card">
          <Row>
            <Col span={8}>
              <Card.Meta
                avatar={
                  <Image
                    width={200}
                    src={updatedRegistry.fixed_asset.image_url}
                    fallback={fallbackImage}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <Form.Item
                hidden
                name={[
                  "asset_registry",
                  `${updatedRegistry.id}`,
                  "start_depreciation_date",
                ]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={updatedRegistry.start_depreciation_date}
              >
                <Input type="hidden" />
              </Form.Item>
              <div style={{ marginLeft: 10 }}>
                <Card.Meta
                  title={t(
                    "activerecord.attributes.fixed_asset.asset_registry_other_info.serial_number",
                  )}
                  description={updatedRegistry.serial_number || "N/A"}
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.received_date")}
                  description={
                    updatedRegistry.start_depreciation_date || (
                      <FillReceiveDate
                        assetRegistry={assetRegistry}
                        onChange={(registry: AssetRegistry) => {
                          setUpdatedRegistry(registry);
                          form.setFieldValue(
                            [
                              "asset_registry",
                              `${updatedRegistry.id}`,
                              "start_depreciation_date",
                            ],
                            registry.start_depreciation_date,
                          );
                        }}
                      />
                    )
                  }
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.book_value")}
                  description={formatCurrency(updatedRegistry.book_value || 0)}
                />
              </div>
            </Col>
            <Col span={8}>
              <Card.Meta
                title={t(
                  "fixed_asset_additions.layout.accumulated_depreciation",
                )}
                description={formatCurrency(
                  updatedRegistry.accumulated_depreciation || 0,
                )}
              />
              <Card.Meta
                title={t("fixed_asset_additions.layout.unbooked_depreciation")}
                description={formatCurrency(
                  updatedRegistry.unbooked_amount || 0,
                )}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
