import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  theme,
  Input,
} from "antd";
import i18next from "i18next";
import dayjs from "dayjs";

import "../styles.css";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { getAssetClassifications } from "api/budgets";
import { AssetClassification } from "types/AssetClassification";
import { getFixedAssets } from "api/FixedAssets";
import { FixedAsset } from "types/FixedAsset";
import { getAssetRegistries, getAssetRegistry } from "api/AssetRegistry";
import { AssetRegistry } from "types/AssetRegistry";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { SubmitButton } from "components/tenant/FixedAssetAdditions/SubmitButton";
import { UseMutationResult, useQuery } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import { validateFixedAssetAddition } from "api/FixedAssetAdditions";
import BillForm from "./BillForm";
import {
  assetClassificationKeys,
  assetRegistryKeys,
  fixedAssetKeys,
} from "features/query-keys";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import RecordContext from "contexts/RecordContext";
import { handleAssetChange, handleClassificationChange } from "../helpers";
import { DepreciationDetails } from "./DepreciationDetails";
import { AssetRegistryList } from "./AssetRegistryList";

const initLifeCycle = (record: FixedAssetAddition): string => {
  if (
    record?.life_cycle_type === "new_date_of_life_cycle" ||
    !record?.life_cycle_type
  ) {
    return "new_date_of_life_cycle";
  }

  return "add_to_life_cycle";
};

export const FixedAssetAdditionCreateBillForm = ({
  mutation,
}: {
  mutation: UseMutationResult<FixedAssetAddition>;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const record = React.useContext(RecordContext) as FixedAssetAddition;

  const transaction =
    record?.fixed_asset_addition_transactions &&
    record.fixed_asset_addition_transactions.find(
      (tr) => tr.transactionable_type === "Bill",
    );
  const [assetClassificationID, setAssetClassificationID] = useState(
    record?.asset_classification?.id || null,
  );
  const [assetID, setAssetID] = useState(record?.fixed_asset?.id || null);
  const [lifeCycle, setLifeCycle] = useState(initLifeCycle(record));
  const [mainAssetRegistry, setMainAssetRegistry] = useState<number>(
    record?.asset_registry_id || null,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [disableAsset, setDisableAsset] = useState(
    !(assetID || assetClassificationID),
  );
  const [disableRegistry, setDisableRegistry] = useState(
    !mainAssetRegistry && disableAsset,
  );

  const formRef = useRef(form.getFieldsValue(true));

  const additionDate = Form.useWatch(["fixed_asset_addition", "date"], form);
  const registryData = Form.useWatch(["asset_registry"], form);
  const componentDisabled = React.useContext(DisabledContext);

  const main = useQuery({
    queryKey: assetRegistryKeys.details(mainAssetRegistry, additionDate),
    queryFn: () => {
      if (!mainAssetRegistry) return;

      const dateString =
        additionDate && dayjs(additionDate).format("YYYY-MM-DD");
      return getAssetRegistry(mainAssetRegistry, {
        depreciation_date: dateString,
      });
    },
  });

  useEffect(() => {
    if (!mainAssetRegistry) {
      return;
    }

    if (
      !componentDisabled &&
      form.getFieldValue("lifecycle") === "new_date_of_life_cycle"
    )
      form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");

    main.refetch();
  }, [mainAssetRegistry, registryData]);

  const selectLifeCycles = (
    <Select
      style={{ width: 120 }}
      defaultValue={
        record?.life_cycle_type !== "new_date_of_life_cycle"
          ? record?.life_cycle_type || "days"
          : "days"
      }
      onChange={(val) =>
        form.setFieldValue(["fixed_asset_addition", "life_cycle_type"], val)
      }
    >
      <Select.Option value="days">Days</Select.Option>
      <Select.Option value="years">Years</Select.Option>
    </Select>
  );

  const handleChangeAssetClassification = (value: string) => {
    setDisableAsset(false);
    setAssetClassificationID(Number(value));
    setAssetID(null);
    setDisableRegistry(true);
    setMainAssetRegistry(null);
    handleClassificationChange(form);
  };

  const handleChangeAsset = (value: string) => {
    setDisableRegistry(false);
    setAssetID(Number(value));
    setMainAssetRegistry(null);
    handleAssetChange(form);
  };

  const showAssetRegistry = (value: string, _option?: any) => {
    const numberValue = Number(value);
    setMainAssetRegistry(numberValue);
  };

  const handleLifeCycleChange = (event: any) => {
    form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");
    form.setFieldValue(
      ["fixed_asset_addition", "life_cycle_type"],
      event.target.value,
    );
    setLifeCycle(event.target.value);
  };

  const handleCreateAddition = async (formValues: HTMLFormElement) => {
    setIsLoading(true);
    try {
      await mutation.mutateAsync(formValues);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const additionForm = (
    <Form
      disabled={!!componentDisabled}
      layout="horizontal"
      name="additionMerge"
      className="label-light"
      form={form}
      onFinish={handleCreateAddition}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      style={{
        fontSize: token.fontSizeSM,
        fontWeight: "normal",
      }}
      autoComplete="off"
      colon={false}
      validateMessages={{
        required: t("activerecord.errors.messages.blank"),
      }}
      requiredMark={(
        label: React.ReactNode,
        { required }: { required: boolean },
      ) => (
        <>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </>
      )}
    >
      <Form.Item
        hidden
        name={["fixed_asset_addition", "addition_type"]}
        initialValue={"create_bill"}
      >
        <Input type="hidden" />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label={t("activerecord.attributes.fixed_asset_addition.reference")}
            name={["fixed_asset_addition", "reference"]}
            key="fixed_asset_addition_reference"
            initialValue={record?.reference}
            rules={[
              {
                required: true,
              },
              {
                validator: async (_, value: string) => {
                  if (formRef.current["reference"] === value || !!record?.id) {
                    return Promise.resolve();
                  }

                  formRef.current = form.getFieldsValue(true);
                  const req = {
                    id: record?.id,
                    fixed_asset_addition: {
                      reference: value,
                    },
                  };
                  const resp = await validateFixedAssetAddition(req);

                  if (resp.errors?.reference)
                    return Promise.reject(
                      new Error(
                        resp.errors?.reference.join(", ") ||
                          "Invalid reference",
                      ),
                    );
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("activerecord.assets.classification.label")}
            name="asset_classification_id"
            key="asset-classification"
            initialValue={assetClassificationID}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record.asset_classification]}
              onChange={handleChangeAssetClassification}
              queryKeyObject={assetClassificationKeys}
              queryFn={getAssetClassifications}
              filters={{
                "q[depreciated_true]": true,
              }}
              dataLabelFn={(cls: AssetClassification) =>
                i18next.language === "en" ? cls.english_name : cls.arabic_name
              }
            />
          </Form.Item>
          <Form.Item
            label={t("activerecord.attributes.fixed_asset_addition.asset_name")}
            name="asset_name"
            key="asset-name"
            initialValue={assetID}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record?.fixed_asset]}
              disabled={disableAsset}
              onChange={handleChangeAsset}
              queryKeyObject={fixedAssetKeys}
              filters={{
                "q[asset_classification_id_eq]": assetClassificationID,
              }}
              queryFn={getFixedAssets}
              dataLabelFn={(asset: FixedAsset) =>
                i18next.language === "en" ? asset.en_name : asset.name
              }
            />
          </Form.Item>
          <Form.Item
            label={t(
              "activerecord.attributes.fixed_asset_addition.asset_reference",
            )}
            name={["fixed_asset_addition", "asset_registry_id"]}
            key="asset-registry"
            initialValue={mainAssetRegistry}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record?.asset_registry]}
              disabled={disableRegistry}
              onChange={showAssetRegistry}
              filters={{
                "q[fixed_asset_id_eq]": assetID,
              }}
              queryKeyObject={assetRegistryKeys}
              queryFn={getAssetRegistries}
              dataLabelFn={(registry: AssetRegistry) => registry.sku}
            />
          </Form.Item>
          <Form.Item
            label={t(
              "activerecord.attributes.fixed_asset_addition.addition_date",
            )}
            name={["fixed_asset_addition", "date"]}
            initialValue={record?.date && dayjs(record?.date)}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                if (main.isLoading) {
                  return false;
                }

                const assetRegistry = main.data || record?.asset_registry;
                const endDay =
                  assetRegistry?.start_depreciation_date &&
                  dayjs(assetRegistry.start_depreciation_date);

                if (!endDay) return true;
                return current && current < endDay;
              }}
            />
          </Form.Item>
          <Form.Item
            initialValue={record?.description}
            label={t(
              "activerecord.attributes.fixed_asset_addition.description",
            )}
            name={["fixed_asset_addition", "description"]}
            rules={[
              {
                validator: async (_, value: string) => {
                  if (value && value.length > 255) {
                    return Promise.reject(t("errors.messages.max_255_allowed"));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea rows={4} autoSize={false} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {!main.isLoading && main.data?.id && (
            <AssetRegistryShow
              assetRegistry={main.data}
              title={t("fixed_asset_additions.layout.main_asset")}
            />
          )}

          {!main.isLoading && main.data?.id && (
            <AssetRegistryList mainRegistry={main.data} />
          )}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t(
              "activerecord.attributes.fixed_asset_addition.increment_life_cycle",
            )}
          </Divider>
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Form.Item
          hidden
          name={["fixed_asset_addition", "life_cycle_type"]}
          initialValue={record?.life_cycle_type}
        >
          <Input type="hidden" value="" />
        </Form.Item>
        <Col span={12}>
          <Form.Item
            name="lifecycle"
            wrapperCol={{ span: 20, offset: 8 }}
            className="radio-checkbox"
            initialValue={lifeCycle}
          >
            <Radio.Group
              onChange={handleLifeCycleChange}
              options={[
                {
                  label: t(
                    "activerecord.attributes.fixed_asset_addition.life_cycle_types.new_date_of_life_cycle",
                  ),
                  value: "new_date_of_life_cycle",
                },
                {
                  label: t(
                    "activerecord.attributes.fixed_asset_addition.life_cycle_types.add_to_life_cycle",
                  ),
                  value: "add_to_life_cycle",
                },
              ]}
            />
          </Form.Item>
          {lifeCycle === "new_date_of_life_cycle" ? (
            <Form.Item
              initialValue={record?.life_cycle && dayjs(record?.life_cycle)}
              name={["fixed_asset_addition", "life_cycle"]}
              wrapperCol={{ span: 20, offset: 8 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultPickerValue={
                  (main.data || record?.asset_registry) &&
                  (main.data || record?.asset_registry).life_cycle_start
                    ? dayjs(
                        (main.data || record?.asset_registry).life_cycle_start,
                      )
                    : dayjs().endOf("day")
                }
                disabledDate={(current) => {
                  if (main.isLoading) {
                    return false;
                  }

                  const assetRegistry = main.data || record?.asset_registry;
                  const endDay = assetRegistry?.life_cycle_start
                    ? dayjs(assetRegistry.life_cycle_start)
                    : dayjs().endOf("day");
                  return current && current < endDay;
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={["fixed_asset_addition", "life_cycle"]}
              initialValue={
                lifeCycle !== "new_date_of_life_cycle" && record.life_cycle
              }
              wrapperCol={{ span: 20, offset: 8 }}
              rules={[
                {
                  required: true,
                },
                {
                  validator: async (_, value: string) => {
                    if (Number(value) > 0) {
                      return Promise.resolve();
                    } else
                      return Promise.reject(
                        new Error(
                          t("activerecord.errors.messages.greater_than"),
                        ),
                      );
                  },
                },
              ]}
            >
              <Input addonAfter={selectLifeCycles} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t("fixed_asset_additions.layout.create_bill")}
          </Divider>
        </Col>
      </Row>
      <BillForm record={transaction?.attributes} />
      <Form.Item name={["fixed_asset_addition", "status"]}>
        <Input type="hidden" value="" />
      </Form.Item>
      {!componentDisabled && main.data && (
        <DepreciationDetails mainRegistry={main.data} />
      )}
      <SubmitButton
        buttonText={t("fixed_asset_additions.buttons.save_and_link_bill")}
      />
    </Form>
  );

  if ((isLoading || mutation.isLoading) && !mutation.isError)
    return <Spin size="large">{additionForm}</Spin>;

  return additionForm;
};
