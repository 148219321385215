import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AssetRegistries, AssetRegistry } from "types/AssetRegistry";
import { CalculationDepreciationEntry } from "./CalculationDepreciationEntry";

export const CalculateDeprecation = ({
  registries,
}: {
  registries: AssetRegistries;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [activeTab, setActiveTab] = useState("depreciation-entries");
  const form = Form.useFormInstance();

  const calculateDepreciation = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsCalculated(true);
    form.setFieldValue("depreciation_calculated", "calculated");
  };

  const depreciated = (
    <Card className="bg-gray-light" style={{ width: "70%", float: "right" }}>
      <span>{t("fixed_asset_additions.layout.depreciation_calculated")}</span>
    </Card>
  );

  const onTabChange = (active: string) => {
    setActiveTab(active);
  };

  const nonDepreciatedRegisteries = registries.filter(
    (register) =>
      !(
        Number(register.unbooked_amount) == 0 ||
        Number(register.accumulated_depreciation) >
          Number(register.unbooked_amount)
      ),
  );

  const isDepreciated = nonDepreciatedRegisteries.length === 0 || isCalculated;

  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t("fixed_asset_additions.layout.depreciation")}
          </Divider>
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Col span={14}>
          <Card
            hidden={isDepreciated}
            className="bg-gray-light"
            style={{ width: "70%", float: "right" }}
          >
            <Form.Item
              hidden
              name="depreciation_calculated"
              rules={[
                {
                  validator: (_rule, value) => {
                    if (value === "calculated") return Promise.resolve();

                    return Promise.reject(
                      new Error("Depreciation should be calculated"),
                    );
                  },
                },
              ]}
            >
              <Input type="hidden" />
            </Form.Item>
            <span>{t("fixed_asset_additions.layout.depreciation_text")}</span>

            <Button
              type="primary"
              onClick={calculateDepreciation}
              style={{
                float: i18next.language === "en" ? "right" : "left",
              }}
            >
              {t("fixed_asset_additions.layout.calculate")}
            </Button>
            <Modal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width="50%"
            >
              <Tabs
                defaultActiveKey="1"
                type="card"
                size="middle"
                onChange={onTabChange}
                activeKey={activeTab}
                items={[
                  {
                    label: t(
                      "activerecord.assets.classification.depreciation_type.posting_depreciation",
                    ),
                    key: "depreciation-entries",
                    children: activeTab === "depreciation-entries" && (
                      <CalculationDepreciationEntry
                        registeries={nonDepreciatedRegisteries}
                      />
                    ),
                  },
                  {
                    label: t(
                      "activerecord.assets.classification.depreciation_type.manual_depreciation",
                    ),
                    key: "manual-depreciation",
                    children: activeTab === "manual-depreciation" && (
                      <CalculationDepreciationEntry
                        registeries={nonDepreciatedRegisteries}
                        isEditable
                      />
                    ),
                  },
                ]}
              />
            </Modal>
          </Card>
          {isDepreciated && depreciated}
        </Col>
      </Row>
    </>
  );
};
