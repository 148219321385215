import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

import { useAppDispatch } from "hooks/permission";
import {
  updatePermission,
  type PermissionData,
} from "features/permissions/PermissionSlice";
import {
  FixedAssetAdditionsTable,
  FixedAssetAdditionNew,
} from "components/tenant/FixedAssetAdditions";
import NoMatch from "components/layout/NoMatch";
import { FixedAssetAdditionEdit } from "components/tenant/FixedAssetAdditions/FixedAssetAdditionEdit";
import { useQuery } from "react-query";
import { currentUserKeys } from "features/query-keys";
import { getCurrentUser } from "api/CurrentUser";
import { Skeleton } from "antd";
import UserContext from "contexts/UserContext";

const FixedAssetAdditions = ({
  permissions,
}: {
  permissions: PermissionData;
}) => {
  const dispatch = useAppDispatch();
  dispatch(
    updatePermission({
      moduleName: "fixedAssetAddition",
      ...permissions,
    }),
  );
  const { data, isLoading } = useQuery({
    queryKey: currentUserKeys.all,
    queryFn: getCurrentUser,
  });

  if (isLoading) return <Skeleton />;

  return (
    <UserContext.Provider value={data}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/tenant/fixed_asset_additions"
            element={<FixedAssetAdditionsTable />}
          />
          <Route
            path="/tenant/fixed_asset_additions/new"
            element={<FixedAssetAdditionNew />}
          />
          <Route
            path="/tenant/fixed_asset_additions/:additionID/edit"
            element={<FixedAssetAdditionEdit />}
          />
          <Route
            path="/tenant/fixed_asset_additions/:additionID"
            element={<FixedAssetAdditionEdit disabled />}
          />
          <Route
            path="/tenant/fixed_asset_additions/new/merge"
            element={<FixedAssetAdditionNew.Merge />}
          />
          <Route
            path="/tenant/fixed_asset_additions/new/link_bill"
            element={<FixedAssetAdditionNew.LinkBill />}
          />
          <Route
            path="/tenant/fixed_asset_additions/new/create_bill"
            element={<FixedAssetAdditionNew.CreateBill />}
          />

          <Route path="/tenant/fixed_asset_additions/*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default FixedAssetAdditions;
