import React, { useContext, useEffect, useRef, useState } from "react";
import type {
  GetRef,
  InputRef,
  TableColumnGroupType,
  TableColumnType,
  TableProps,
} from "antd";
import { Button, Form, Input, Popconfirm, Table } from "antd";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const form = Form.useFormInstance();
  if (form)
    return (
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    );

  const [newForm] = Form.useForm();
  return (
    <Form form={newForm} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  keepEditable?: boolean;
  formName?: string | number | (string | number)[];
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  formName,
  record,
  keepEditable,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(keepEditable ?? false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const formFieldName = formName || dataIndex;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (keepEditable) return;

    setEditing(!editing);

    form.setFieldValue(formFieldName, record[dataIndex]);
  };

  const save = async () => {
    const value = form.getFieldValue(formFieldName);

    toggleEdit();
    handleSave({ ...record, [dataIndex]: value });
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={formFieldName}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

interface EditableTableProps extends TableProps {
  deletableRow?: boolean;
  addMore?: boolean;
  keepEditable?: boolean;
}

type EditableColumnType = (TableColumnType<any> | TableColumnGroupType<any>) & {
  editable?: boolean;
  dataIndex: string;
  formName?: (record: any) => string | number | (string | number)[];
};

const EditableTable = (props: EditableTableProps) => {
  const {
    dataSource,
    columns,
    deletableRow,
    addMore,
    keepEditable,
    ...restProps
  } = props;
  const [tableDataSource, setTableDataSource] = useState(dataSource ?? []);

  const [count, setCount] = useState(dataSource.length);

  const handleDelete = (key: React.Key) => {
    const newData = tableDataSource.filter((item) => item.key !== key);
    setTableDataSource(newData);
  };

  const defaultColumns: EditableColumnType[] = [
    ...columns,
  ] as EditableColumnType[];

  if (deletableRow) {
    defaultColumns.push({
      title: "operation",
      dataIndex: "operation",
      render: (_, record: any) =>
        dataSource.length > 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    });
  }

  const handleAdd = () => {
    const newData = {
      key: count,
    };
    setTableDataSource([...tableDataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: any) => {
    const newData = [...tableDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTableDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const dataColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        formName: col.formName && col.formName(record),
        keepEditable,
        handleSave,
      }),
    };
  });

  return (
    <Table
      {...restProps}
      components={components}
      rowClassName={() => "editable-row"}
      dataSource={tableDataSource}
      columns={dataColumns as EditableColumnType[]}
      footer={() =>
        addMore ? (
          <Button
            onClick={handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            Add a row
          </Button>
        ) : (
          ""
        )
      }
    />
  );
};

export default EditableTable;
