import React, { useEffect, useRef, useState } from "react";
import { ConfigProvider, Input, Select, SelectProps, Spin } from "antd";
import { useQuery } from "react-query";
import { uniqBy, compact, isEqual } from "lodash";
import { QueryKeyType } from "types/query-keys";

interface SelectWithSearchProps extends SelectProps {
  queryFn: (options: any) => Promise<any>;
  queryKeyObject: QueryKeyType;
  dataLabelFn?: (data: any) => string;
  initialOpts?: any;
  disabled?: boolean;
  filters?: any;
}

export const SelectWithSearch = (props: SelectWithSearchProps) => {
  const {
    queryFn,
    queryKeyObject,
    dataLabelFn = undefined,
    initialOpts = [],
    disabled = false,
    filters = {},
    onChange,
    ...selectOnlyProps
  } = props;

  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [dropDownData, setDropDownData] = useState(compact(initialOpts || []));
  const [selectedValueItem, setSelectedValueItem] = useState(
    disabled ? null : props.value,
  );

  const filterRef = useRef(filters);

  const fetchDropDownData = async (search: string, page: number) => {
    if (disabled) {
      return [];
    }

    try {
      return queryFn({
        search,
        page,
        filters,
      });
    } catch (_error) {
      console.log(_error);
      return [];
    }
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeyObject.paginate({ filters, search }, pageNumber),
    queryFn: () => fetchDropDownData(search, pageNumber),
    enabled: !disabled,
    onSuccess: (res) => {
      if (isEqual(filterRef.current, filters)) {
        setDropDownData(uniqBy([...dropDownData, ...res], "id"));
      } else {
        setSelectedValueItem(null);
        setDropDownData(res);
        filterRef.current = filters;
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [search, pageNumber]);

  const handleSearch = (keyword: string) => {
    setDropDownData([]);
    setSearch(keyword);
    setPageNumber(1);
  };

  const handleScroll = (e: any) => {
    e.persist();
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      data.length >= 20
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleChange = (value: string, option: any) => {
    setSelectedValueItem(value);
    onChange && onChange(value, option);
  };

  const handleFocus = () => {
    setSearch("");
  };

  let selectOptions = dropDownData;
  if (dataLabelFn) {
    selectOptions = dropDownData.map((dt: any) => ({
      value: dt.id,
      label: dataLabelFn(dt),
      ...dt,
    }));
  }

  const { componentDisabled } = ConfigProvider.useConfig();

  if (componentDisabled) {
    const val =
      (selectOptions[0]?.options && selectOptions[0].options[0]?.label) ||
      selectOptions[0]?.label;
    return <Input value={val} />;
  }

  const selectDropDown = (
    <Select
      options={selectOptions}
      value={selectedValueItem}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      showSearch={true}
      loading={true}
      disabled={componentDisabled || disabled}
      notFoundContent={<span>Not Found</span>}
      onChange={handleChange}
      onFocus={handleFocus}
      onPopupScroll={handleScroll}
      {...selectOnlyProps}
    />
  );

  if (isLoading) {
    return <Spin size="small">{selectDropDown}</Spin>;
  } else return selectDropDown;
};
