import { useSettings } from "hooks/Settings";
import i18next from "i18next";

export const formatCurrency = (value: number) => {
  const settings = useSettings();

  const numberValue = Number(value) || 0;

  if (i18next.language === "en")
    return settings.currency + " " + roundNumber(numberValue);
  else return roundNumber(numberValue) + " " + settings.currency;
};

export const roundNumber = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
